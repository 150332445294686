import styled from '@mui/material/styles/styled'
import { LinkAsButton } from '@components/UI/Button'

export const StyledNewsletterContainer = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up(375)]: {
    width: 375,
  },
}))

export const StyledHeader = styled('div', {
  name: 'NewsLetter',
  slot: 'Header',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
  color: theme.palette.text.light.primary,
  backgroundColor: theme.palette.background.dark.primary,
  height: theme.spacing(17),
}))

export const StyledNewsletterBody = styled('div', {
  name: 'NewsLetter',
  slot: 'Body',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  padding: theme.spacing(4),
  fontSize: theme.typography.body2.fontSize,
  lineHeight: 1.43,

  button: {
    height: theme.spacing(12),
  },
}))

export const StyledNewsletterTypography = styled('div', {
  name: 'NewsLetter',
  slot: 'Typography',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.dark.primary,
}))

export const StyledNewsletterDescription = styled(StyledNewsletterTypography, {
  name: 'NewsLetter',
  slot: 'Description',
})(({ theme }) => ({
  marginBottom: theme.spacing(8),
}))

export const DiscountMessageTypography = styled(StyledNewsletterTypography, {
  name: 'DiscountMessage',
  slot: 'Typography',
})(() => ({
 marginBottom: 0
}))

export const StyledNewsletterTypographyTitle = styled('div', {
  name: 'NewsLetter',
  slot: 'TypographyTitle',
})(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: 'bold',
}))

export const StyledNewsletterTextFieldWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'TextFieldWrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

export const StyledNewsletterPrivacyText = styled('p', {
  name: 'NewsLetter',
  slot: 'PrivacyText',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.light.secondary,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),

  a: {
    color: theme.palette.background.dark.secondary,
    fontWeight: 700,
    textDecoration: 'underline'
  },
}))

export const ThankYouContainer = styled('div', {
  name: 'NewsLetterThankYou',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const StyledPromoCodeTextField = styled('div', {
  name: 'NewsLetterPromoCode',
  slot: 'TextField',
})(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  border: `solid 1px ${theme.palette.text.dark.primary}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '5px',
  cursor: 'pointer',
  color: theme.palette.background.dark.secondary,
  textDecoration: 'underline',
  fontWeight: 700,
}))

export const StyledPromoCodeSpan = styled('span', {
  name: 'NewsLetterPromoCode',
  slot: 'Span',
})(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 600,
  textDecoration: 'underline',
}))

export const StyledLinkAsButton = styled(LinkAsButton, {
  name: 'NewsLetter',
  slot: 'LinkAsButton',
})(({ theme }) => ({
  margin: `${theme.spacing(2)} 0 0 0`,
}))
