import { useGetPageStaticLandingQuery } from '@features/cms/query'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import { IPictureMedia, isPictureMedia } from '@typesApp/teaser'
import { ICMAction } from '@typesApp/cmsPlacement/CMAction'

import {
  NEWSLETTER_DESCRIPTION_PLACEMENT,
  NEWSLETTER_MEDIA_PLACEMENT,
  NEWSLETTER_PRIVACY_PLACEMENT,
  NEWSLETTER_SUBSCRIBE_BUTTON_ACTION_NAME,
  NEWSLETTER_SUBSCRIBE_BUTTON_PLACEMENT,
  NEWSLETTER_SUBSCRIBE_PAGE_LANDING_EXTRA_ITEMS,
  NEWSLETTER_TEXTFIELD_PLACEMENT,
  NEWSLETTER_TEXTFIELD_VIEWTYPE,
} from '@constants/newsletter'
import { NEWSLETTER_DRAWER_DEFAULT_ID, NEWSLETTER_DRAWER_PROMO_ID, PROMO_URL_PARAM } from '@constants/common'
import { isEarlyAccesFeatureEnabled, isEarlyAccessFeatureNewsLetterEnabled } from '@utils/common'
import { useSearchParams } from 'next/navigation'

export interface INewsletterSubscription {
  title: string
  description: string
  media: IPictureMedia | undefined
  isTextFieldExists: boolean
  emailLabel: string
  privacyText: string
  isSubscribeButtonExists: boolean
  subscribeCtaText: string
  landingPageMedia: ILXTeaser[]
}

export const useNewsletterSubscription = (): INewsletterSubscription => {
  const isEarlyAccessEnabled = isEarlyAccesFeatureEnabled()
  const isEANewsLetterEnabled = isEarlyAccessFeatureNewsLetterEnabled()
  const searchParams = useSearchParams()
  const promo = searchParams.get(PROMO_URL_PARAM)
  const isPromo = isEarlyAccessEnabled && (!!promo || isEANewsLetterEnabled)

  const params = {
    pageId: isPromo ? NEWSLETTER_DRAWER_PROMO_ID : NEWSLETTER_DRAWER_DEFAULT_ID,
  }
  const { data } = useGetPageStaticLandingQuery(params, { skip: false })

  const contentPlacements = data?.contentPlacements

  const getContentPlacement = (placementName: string) =>
    contentPlacements?.filter(placement => placement.name === placementName)[0] ?? null

  const title = data?.title ?? ''

  const descriptionItem = getContentPlacement(NEWSLETTER_DESCRIPTION_PLACEMENT)?.items[0] as ILXTeaser
  const description = descriptionItem?.teaserText2 ?? ''

  const mediaItem = getContentPlacement(NEWSLETTER_MEDIA_PLACEMENT)?.items[0]
  const media = mediaItem?.media?.find(isPictureMedia)

  const textFieldItem = getContentPlacement(NEWSLETTER_TEXTFIELD_PLACEMENT)?.items[0] as ICMPlaceholder
  const isTextFieldExists = textFieldItem?.viewtype === NEWSLETTER_TEXTFIELD_VIEWTYPE
  const emailLabel = textFieldItem?.title ?? ''

  const privacyTextItem = getContentPlacement(NEWSLETTER_PRIVACY_PLACEMENT)?.items[0] as ILXTeaser
  const privacyText = privacyTextItem?.teaserText2 ?? ''

  const subscribeButtonItem = getContentPlacement(NEWSLETTER_SUBSCRIBE_BUTTON_PLACEMENT)
    ?.items[0] as unknown as ICMAction
  const isSubscribeButtonExists = subscribeButtonItem?.idAction === NEWSLETTER_SUBSCRIBE_BUTTON_ACTION_NAME
  const subscribeCtaText = subscribeButtonItem?.title ?? ''

  const landingPageMedia = getContentPlacement(NEWSLETTER_SUBSCRIBE_PAGE_LANDING_EXTRA_ITEMS)?.items as ILXTeaser[]

  return {
    title,
    description,
    media,
    isTextFieldExists,
    emailLabel,
    privacyText,
    isSubscribeButtonExists,
    subscribeCtaText,
    landingPageMedia,
  }
}
